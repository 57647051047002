import { AppContainer } from 'nchain-design-system';
import { SidebarCategory } from 'nchain-design-system/lib/components/Sidebar/Sidebar.interface';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import SidebarLogo from '../../../assets/Logo-sidebar.png';
import SidebarLogoMobile from '../../../assets/Logo-sidebar-mobile.png';
import Navbar from '../Navbar/Navbar';
import styles from './Layout.module.scss';
import { sidebarCategories } from './SidebarCategories';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <AppContainer
      SidebarProps={{
        homePath: sidebarCategories?.[0].subCategories[0],
        categories: sidebarCategories,
        autoOpenOnCategorySelect: true,
        expandOnLogoClick: true,
        largeLogo: (
          <Link to={sidebarCategories?.[0].subCategories[0].pathname}>
            <img src={SidebarLogo} alt={t('common.logo_alt')} />
          </Link>
        ),
        smallLogo: (
          <Link to={sidebarCategories?.[0].subCategories[0].pathname}>
            <img src={SidebarLogoMobile} alt={t('common.logo_alt')} />
          </Link>
        ),
        handleCategoryClick: (category: SidebarCategory) =>
          navigate(category.pathname),
      }}
      navbar={<Navbar />}
      className={styles.main}
      classes={{ root: styles.root }}
    >
      {children}
    </AppContainer>
  );
};

export default Layout;
