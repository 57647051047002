import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { push } from 'redux-first-history';
import { put } from 'redux-saga/effects';
import instance from 'src/axios';
import { apiRoutes } from 'src/constants/apiConstants';
import { routes } from 'src/routes';

import { parseJwt } from '../../utils/jwtTokenUtils';
import { login, loginSuccess } from '../actions/authActions';
import { addError, setGlobalLoading } from '../features/globalSlice';
import { User } from '../models/Auth';
import { Roles } from '../models/Users';

export function* AuthLoginSaga(action: ReturnType<typeof login>): Generator {
  yield put(setGlobalLoading(true));
  try {
    const response = (yield instance.post(
      `${apiRoutes.LOGIN}`,
      {
        ...action.payload,
      },
      {}
    )) as AxiosResponse<User>;

    const parsedToken = parseJwt(response.data.token);

    if (parsedToken.roles.includes(Roles.ADMIN)) {
      yield put(loginSuccess(response.data));
      yield put(push(routes.USERS));
    } else {
      yield put(
        addError({
          actionType: action.type,
          error: i18next.t('common.login_error_no_admin'),
        })
      );
    }
  } catch (e) {
    const error = e as Error;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(setGlobalLoading(false));
  }
}
