export enum routes {
  LOGIN = '/login',
  PAGE_NOT_FOUND = '/404',
  USERS = '/users',
  USERS_LIST = '/users-list',
  USERS_FOR_CONFIRMATION = '/users-for-confirmation',
  NEWS = '/news',
  NEWS_LIST = '/news-list',
  COMMENTS = '/comments',
  PRODUCTS = '/products',
  PRODUCTS_LIST = '/products-list',
  FAQ = '/faq',
  SIDE_EFFECTS = '/side-effects',
  PRODUCT = '/product',
}
