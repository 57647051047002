import axios, { AxiosError, AxiosResponse } from 'axios';
import i18next from 'i18next';
import { SnackbarType } from 'nchain-design-system';
import { all, call, put } from 'redux-saga/effects';

import instance from '../../axios';
import { CustomTableFilterProps } from '../../components/ui/Table/Table';
import { apiRoutes } from '../../constants/apiConstants';
import { cleanQueryParams, prepareParams } from '../../utils/useQueryParams';
import {
  addProduct,
  deleteProduct,
  editProduct,
  getProductById,
  getProductSideEffects,
  productsLoad,
  productsLoaded,
} from '../actions/productsActions';
import {
  addError,
  addSnackbar,
  removeModal,
  setGlobalLoading,
  startLoading,
  stopLoading,
} from '../features/globalSlice';
import { fetchSideEffectsSetSuccess } from '../features/productsSlice';
import {
  ProductList,
  ProductResponse,
  SideEffectsResponse,
} from '../models/Products';

export function* ProductsLoadedSaga(
  action: ReturnType<typeof productsLoad>
): Generator {
  const params = prepareParams(
    action.payload as CustomTableFilterProps<ProductList>
  );
  const newParams = cleanQueryParams(params);

  yield put(setGlobalLoading(true));
  try {
    const response = (yield instance.get(
      `${apiRoutes.PRODUCTS}${newParams}`
    )) as AxiosResponse<ProductList>;

    yield put(productsLoaded(response.data));
  } catch (e) {
    const error = e as Error;
    yield put(addError({ actionType: action.type, error: error.message }));
    yield put(
      addSnackbar({
        type: SnackbarType.FAILURE,
        title: i18next.t('common.snackbar_error_title'),
        body: i18next.t('common.snackbar_error_body'),
      })
    );
  } finally {
    yield put(setGlobalLoading(false));
  }
}

export function* DeleteProductSaga(
  action: ReturnType<typeof deleteProduct>
): Generator {
  yield put(setGlobalLoading(true));
  try {
    yield instance.delete(`${apiRoutes.PRODUCTS}/${action.payload.id}`);
    yield put(
      addSnackbar({
        type: SnackbarType.SUCCESS,
        title: i18next.t('product_snackbar.success_title'),
        body: i18next.t('product_snackbar.success_body'),
      })
    );

    action.payload.func();
  } catch (e) {
    const error = e as Error;
    yield put(
      addSnackbar({
        type: SnackbarType.FAILURE,
        title: i18next.t('product_snackbar.error_title'),
        body: i18next.t('product_snackbar.error_body'),
      })
    );
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(removeModal());
    yield put(setGlobalLoading(false));
  }
}

export function* AddProductSaga(
  action: ReturnType<typeof addProduct>
): Generator {
  yield put(
    startLoading({
      actionType: action.type,
    })
  );

  const formData = new FormData();
  const file = yield fetch(action.payload.product.file.url)
    .then((res) => res.blob())
    .then(
      (blobFile) =>
        new File([blobFile], action.payload.product.file.name, {
          type: 'image/*',
        })
    );

  formData.append('name', action.payload.product.name);
  formData.append('description', action.payload.product.description);
  formData.append('dosage', action.payload.product.dosage);
  formData.append('categories', action.payload.product.categories.join(','));
  formData.append('subcategories',
      action.payload.product.subcategories.join(',') || '');
  formData.append('file', file as File);

  yield all(
    action.payload.product.attachments.map((att) => {
      return call(async () => {
        const attFile = await fetch(att.url)
          .then((res) => res.blob())
          .then(
            (blobFile) =>
              new File([blobFile], att.name, { type: 'text/* image/*' })
          );

        formData.append(`attachments`, attFile as File);
      });
    })
  );

  try {
    yield instance.post(`${apiRoutes.PRODUCTS}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (e) {
    const error = e as AxiosError;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(stopLoading({ actionType: action.type }));
  }
}

export function* GetProductByIdSaga(
  action: ReturnType<typeof getProductById>
): Generator {
  yield put(setGlobalLoading(true));
  try {
    const response = (yield instance.get(
      `${apiRoutes.PRODUCTS}/${action.payload.id}`
    )) as AxiosResponse<ProductResponse>;

    const file = (yield fetch(response.data.imageUrl)
      .then((res) => res.blob())
      .then(
        (blobFile) =>
          new File(
            [blobFile],
            `${response.data.id}.${response.data.imageUrl.substring(
              response.data.imageUrl.length - 3
            )}`,
            { type: 'image/*' }
          )
      )) as File;

    const newAttachments: File[] = [];

    if (!action.payload.detailsOnly) {
      for (let i = 0; i < response.data.attachments.length; i++) {
        const att = response.data.attachments[i];
        const newAtt = (yield fetch(att.url)
          .then((res) => res.blob())
          .then(
            (blobFile) =>
              new File([blobFile], att.name, {
                type: 'text/* image/*',
              })
          )) as File;
        newAttachments.push(newAtt);
      }
    }

    action.payload.onSuccess({
      ...response.data,
      file,
      attachments: newAttachments,
    });
  } catch (e) {
    const error = e as AxiosError;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(setGlobalLoading(false));
  }
}

export function* EditProductSaga(
  action: ReturnType<typeof editProduct>
): Generator {
  yield put(startLoading({ actionType: action.type }));

  const file = yield fetch(action.payload.productData.file.url)
    .then((res) => res.blob())
    .then(
      (blobFile) =>
        new File([blobFile], action.payload.productData.file.name, {
          type: 'image/*',
        })
    );

  try {
    const attFormData = new FormData();
    if (action.payload.productData.attachments.length > 0) {
      for (let i = 0; i < action.payload.productData.attachments.length; i++) {
        const attachment = action.payload.productData.attachments[i];
        const attFile = yield fetch(attachment.url)
          .then((res) => res.blob())
          .then(
            (blobFile) =>
              new File([blobFile], attachment.name, { type: 'text/* image/*' })
          );
        attFormData.append('files', attFile as File);
      }
    }

    const requestOne = yield instance.patch(
      `${apiRoutes.PRODUCTS}/${action.payload.id}`,
      {
        name: action.payload.productData.name,
        description: action.payload.productData.description,
        dosage: action.payload.productData.dosage,
        categories: action.payload.productData.categories || [],
        subcategories: action.payload.productData.subcategories || [],
      }
    );

    const formData = new FormData();
    formData.append('file', file as File);

    const requestTwo = yield instance.patch(
      `${apiRoutes.PRODUCTS}/${action.payload.id}/image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    const requestThree =
      action.payload.deletedFilesIndexes.length > 0
        ? yield instance.delete(
            `${apiRoutes.PRODUCTS}/${action.payload.id}/attachments`,
            {
              data: {
                indices: action.payload.deletedFilesIndexes,
              },
            }
          )
        : undefined;

    const requestFour =
      action.payload.productData.attachments.length > 0
        ? yield instance.post(
            `${apiRoutes.PRODUCTS}/${action.payload.id}/attachments`,
            attFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          )
        : undefined;

    yield axios
      .all([requestOne, requestTwo, requestThree, requestFour])
      .then(
        axios.spread((...responses) => {
          // when you need, use responses here
        })
      )
      .catch((err) => {
        throw err;
      });
  } catch (e) {
    const error = e as AxiosError;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(stopLoading({ actionType: action.type }));
  }
}

export function* GetProductSideEffectsSaga(
  action: ReturnType<typeof getProductSideEffects>
): Generator {
  yield put(setGlobalLoading(true));
  try {
    const response = (yield instance.get(
      `${apiRoutes.PRODUCTS}/${action.payload}/side-effects`
    )) as AxiosResponse<SideEffectsResponse>;
    yield put(fetchSideEffectsSetSuccess(response.data.sideEffects));
  } catch (e) {
    const error = e as AxiosError;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(setGlobalLoading(false));
  }
}
