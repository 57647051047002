import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoadingProvider from 'src/components/ui/LoadingProvider/LoadingProvider';
import { routes } from 'src/routes';

import Layout from '../../ui/Layout/Layout';

const CommentsList = React.lazy(
  () => import('../Comments/CommentsList/CommentsList')
);
const ProductsList = React.lazy(
  () => import('../Products/ProductsList/ProductsList')
);
const SideEffect = React.lazy(() => import('../SideEffects/SideEffects'));
const UsersForApproveList = React.lazy(
  () => import('../Users/UsersForApproveList/UsersForApproveList')
);
const Faq = React.lazy(() => import('../Faq/Faq'));
const NewsList = React.lazy(() => import('../News/NewsList/NewsList'));
const UsersList = React.lazy(() => import('../Users/UsersList/UsersList'));

const AuthenticatedRouter = () => {
  const subRoute = (route: string) => {
    return route.replace('/', '');
  };

  return (
    <Layout>
      <Suspense fallback={<LoadingProvider loading={true} />}>
        <Routes>
          <Route path={routes.USERS}>
            <Route
              index
              element={
                <Navigate to={`${routes.USERS}${routes.USERS_LIST}`} replace />
              }
            />
            <Route path={subRoute(routes.USERS_LIST)} element={<UsersList />} />
            <Route
              path={subRoute(routes.USERS_FOR_CONFIRMATION)}
              element={<UsersForApproveList />}
            />
          </Route>
          <Route path={routes.NEWS}>
            <Route
              index
              element={
                <Navigate to={`${routes.NEWS}${routes.NEWS_LIST}`} replace />
              }
            />
            <Route path={subRoute(routes.NEWS_LIST)} element={<NewsList />} />
            <Route
              path={subRoute(routes.COMMENTS)}
              element={<CommentsList />}
            />
          </Route>
          <Route path={routes.PRODUCTS}>
            <Route
              index
              element={
                <Navigate
                  to={`${routes.PRODUCTS}${routes.PRODUCTS_LIST}`}
                  replace
                />
              }
            />
            <Route
              path={subRoute(routes.PRODUCTS_LIST)}
              element={<ProductsList />}
            />
            <Route
              path={`${subRoute(routes.FAQ)}/:productId`}
              element={<Faq />}
            />
            <Route
              path={`${subRoute(routes.SIDE_EFFECTS)}/:productId`}
              element={<SideEffect />}
            />
          </Route>
          <Route
            path={'*'}
            element={<Navigate to={`${routes.USERS}${routes.USERS_LIST}`} />}
          />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default AuthenticatedRouter;
