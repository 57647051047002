import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { SnackbarType } from 'nchain-design-system';
import { put } from 'redux-saga/effects';

import instance from '../../axios';
import { CustomTableFilterProps } from '../../components/ui/Table/Table';
import { apiRoutes } from '../../constants/apiConstants';
import { cleanQueryParams, prepareParams } from '../../utils/useQueryParams';
import {
  approveNewUser,
  createNewUser,
  usersForApproveLoad,
  usersForApproveLoaded,
  usersLoad,
  usersLoaded,
} from '../actions/usersActions';
import {
  addError,
  addSnackbar,
  setGlobalLoading,
  startLoading,
  stopLoading,
} from '../features/globalSlice';
import { UsersList } from '../models/Users';

export function* UsersLoadedSaga(
  action: ReturnType<typeof usersLoad>
): Generator {
  const params = prepareParams(
    action.payload as CustomTableFilterProps<UsersList>
  );
  const newParams = cleanQueryParams(params);

  yield put(setGlobalLoading(true));
  try {
    const response = (yield instance.get(
      `${apiRoutes.USERS}${newParams}`
    )) as AxiosResponse<UsersList>;

    yield put(usersLoaded(response.data));
  } catch (e) {
    const error = e as Error;
    yield put(addError({ actionType: action.type, error: error.message }));
    yield put(
      addSnackbar({
        type: SnackbarType.FAILURE,
        title: i18next.t('common.snackbar_error_title'),
        body: i18next.t('common.snackbar_error_body'),
      })
    );
  } finally {
    yield put(setGlobalLoading(false));
  }
}

export function* UsersForApproveLoadedSaga(
  action: ReturnType<typeof usersForApproveLoad>
): Generator {
  const params = prepareParams(
    action.payload as CustomTableFilterProps<UsersList>
  );
  const newParams = cleanQueryParams(params);

  yield put(setGlobalLoading(true));
  try {
    const response = (yield instance.get(
      `${apiRoutes.USERS}${newParams}&approved=false`
    )) as AxiosResponse<UsersList>;

    yield put(usersForApproveLoaded(response.data));
  } catch (e) {
    const error = e as Error;
    yield put(addError({ actionType: action.type, error: error.message }));
    yield put(
      addSnackbar({
        type: SnackbarType.FAILURE,
        title: i18next.t('common.snackbar_error_title'),
        body: i18next.t('common.snackbar_error_body'),
      })
    );
  } finally {
    yield put(setGlobalLoading(false));
  }
}

export function* CreateNewUserSaga(
  action: ReturnType<typeof createNewUser>
): Generator {
  yield put(startLoading({ actionType: action.type }));
  try {
    yield instance.post(`${apiRoutes.NEW_USER}`, action.payload);
  } catch (e) {
    const error = e as Error;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(stopLoading({ actionType: action.type }));
    yield put(setGlobalLoading(false));
  }
}

export function* ApproveNewUserSaga(
  action: ReturnType<typeof approveNewUser>
): Generator {
  yield put(startLoading({ actionType: action.type }));
  try {
    yield instance.patch(`${apiRoutes.APPROVE_NEW_USER}/${action.payload}`);
  } catch (e) {
    const error = e as Error;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(stopLoading({ actionType: action.type }));
    yield put(setGlobalLoading(false));
  }
}
