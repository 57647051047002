import axios, { AxiosError, AxiosResponse } from 'axios';
import i18next from 'i18next';
import { SnackbarType } from 'nchain-design-system';
import { put } from 'redux-saga/effects';
import { CustomTableFilterProps } from 'src/components/ui/Table/Table';
import { apiRoutes } from 'src/constants/apiConstants';
import { cleanQueryParams, prepareParams } from 'src/utils/useQueryParams';

import instance from '../../axios';
import {
  createNewNews,
  deleteNews,
  getNewsById,
  newsLoad,
  newsLoaded,
  patchNews,
} from '../actions/newsActions';
import {
  addError,
  addSnackbar,
  removeModal,
  setGlobalLoading,
  startLoading,
  stopLoading,
} from '../features/globalSlice';
import { NewsDetails, NewsList } from '../models/News';

export function* NewsLoadedSaga(
  action: ReturnType<typeof newsLoad>
): Generator {
  const params = prepareParams(
    action.payload as CustomTableFilterProps<NewsList>
  );
  const newParams = cleanQueryParams(params);

  yield put(setGlobalLoading(true));
  try {
    const response = (yield instance.get(
      `${apiRoutes.NEWS}${newParams}`
    )) as AxiosResponse<NewsList>;

    yield put(newsLoaded(response.data));
  } catch (e) {
    const error = e as Error;
    yield put(addError({ actionType: action.type, error: error.message }));
    yield put(
      addSnackbar({
        type: SnackbarType.FAILURE,
        title: i18next.t('common.snackbar_error_title'),
        body: i18next.t('common.snackbar_error_body'),
      })
    );
  } finally {
    yield put(setGlobalLoading(false));
  }
}

export function* GetNewsByIdSaga(
  action: ReturnType<typeof getNewsById>
): Generator {
  yield put(setGlobalLoading(true));
  try {
    const response = (yield instance.get(
      `${apiRoutes.NEWS}/${action.payload.id}`
    )) as AxiosResponse<NewsDetails>;

    const file = (yield fetch(response.data.headerImageUrl)
      .then((r) => r.blob())
      .then(
        (blobFile) =>
          new File(
            [blobFile],
            `${response.data.id}.${response.data.headerImageUrl.substring(
              response.data.headerImageUrl.length - 3
            )}`,
            { type: 'image/*' }
          )
      )) as File;

    action.payload.onSuccess({ ...response.data, file });
  } catch (e) {
    const error = e as Error;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(setGlobalLoading(false));
  }
}

export function* CreateNewNewsSaga(
  action: ReturnType<typeof createNewNews>
): Generator {
  yield put(startLoading({ actionType: action.type }));
  const formData = new FormData();
  const file = yield fetch(action.payload.file.url)
    .then((r) => r.blob())
    .then(
      (blobFile) =>
        new File([blobFile], action.payload.file.name, { type: 'image/*' })
    );
  formData.append('title', action.payload.title);
  formData.append('article', action.payload.article);
  formData.append('categories', action.payload.categories.join(','));
  formData.append(
    'subcategories',
    action.payload.subcategories.join(',') || ''
  );
  formData.append('file', file as File);

  try {
    yield instance.post(`${apiRoutes.NEWS}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (e) {
    const error = e as AxiosError;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(stopLoading({ actionType: action.type }));
  }
}

export function* PatchNewsSaga(
  action: ReturnType<typeof patchNews>
): Generator {
  yield put(startLoading({ actionType: action.type }));

  const file = yield fetch(action.payload.file.url)
    .then((r) => r.blob())
    .then(
      (blobFile) =>
        new File([blobFile], action.payload.file.name, { type: 'image/*' })
    );

  try {
    const requestOne = instance.patch(
      `${apiRoutes.NEWS}/${action.payload.id}`,
      {
        title: action.payload.title,
        categories: action.payload.categories,
        subcategories: action.payload.subcategories
          ? action.payload.subcategories
          : [],
        article: action.payload.article,
      }
    );

    const formData = new FormData();
    formData.append('file', file as File);

    const requestTwo = instance.patch(
      `${apiRoutes.NEWS}/${action.payload.id}/image`,
      formData
    );

    yield axios
      .all([requestOne, requestTwo])
      .then(
        axios.spread((...responses) => {
          // when you need, use responses here
        })
      )
      .catch((errors) => {
        throw errors;
      });
  } catch (e) {
    const error = e as AxiosError;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(stopLoading({ actionType: action.type }));
  }
}

export function* DeleteNewsSaga(
  action: ReturnType<typeof deleteNews>
): Generator {
  yield put(setGlobalLoading(true));
  try {
    yield instance.delete(`${apiRoutes.NEWS}/${action.payload.id}`);
    yield put(
      addSnackbar({
        type: SnackbarType.SUCCESS,
        title: i18next.t('news_snackbar.success_title'),
        body: i18next.t('news_snackbar.success_body'),
      })
    );

    action.payload.func();
  } catch (e) {
    const error = e as Error;
    yield put(
      addSnackbar({
        type: SnackbarType.FAILURE,
        title: i18next.t('news_snackbar.error_title'),
        body: i18next.t('news_snackbar.error_body'),
      })
    );
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(removeModal());
    yield put(setGlobalLoading(false));
  }
}
