import { createAction } from '@reduxjs/toolkit';

import { CustomTableFilterProps } from '../../components/ui/Table/Table';
import {
  AddNewsFormData,
  GetNewsAction,
  NewsList,
  PatchNewsFormData,
} from '../models/News';

export const NEWS_SLICE = 'news';

export const newsLoad = createAction<CustomTableFilterProps<NewsList>>(
  `${NEWS_SLICE}/load`
);
export const newsLoaded = createAction<NewsList>(`${NEWS_SLICE}/loaded`);
export const getNewsById = createAction<GetNewsAction>(
  `${NEWS_SLICE}/getNewsById`
);
export const createNewNews = createAction<AddNewsFormData>(
  `${NEWS_SLICE}/create`
);
export const patchNews = createAction<PatchNewsFormData>(`${NEWS_SLICE}/patch`);
export const deleteNews = createAction<{ id: string; func: () => void }>(
  `${NEWS_SLICE}/delete`
);
