import { AxiosError, AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';

import instance from '../../axios';
import { apiRoutes } from '../../constants/apiConstants';
import { getProfile } from '../actions/profileActions';
import { addError, startLoading, stopLoading } from '../features/globalSlice';
import { getProfileSetSuccess } from '../features/profileSlice';
import { Profile } from '../models/Profile';

export function* GetProfileSaga(
  action: ReturnType<typeof getProfile>
): Generator {
  yield put(
    startLoading({
      actionType: action.type,
    })
  );

  try {
    const response = (yield instance.get(
      `${apiRoutes.PROFILE}`
    )) as AxiosResponse<Profile>;
    yield put(getProfileSetSuccess(response.data));
  } catch (e) {
    const error = e as AxiosError;
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(stopLoading({ actionType: action.type }));
  }
}
