import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PRODUCTS_SLICE, productsLoaded } from '../actions/productsActions';
import { ProductList, SideEffect } from '../models/Products';

export interface ProductsState {
  productsList: ProductList;
  sideEffects: SideEffect[];
}

const initialState: ProductsState = {
  productsList: null,
  sideEffects: null,
};

const productsSlice = createSlice({
  name: PRODUCTS_SLICE,
  initialState,
  reducers: {
    fetchSideEffectsSetSuccess(state, action: PayloadAction<SideEffect[]>) {
      state.sideEffects = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(productsLoaded, (state, action) => {
      state.productsList = action.payload;
    });
  },
});

export const { fetchSideEffectsSetSuccess } = productsSlice.actions;
export default productsSlice.reducer;
