export enum apiRoutes {
  LOGIN = '/account/login',
  NEWS = '/news',
  USERS = '/admin/users',
  NEW_USER = '/admin/invite',
  APPROVE_NEW_USER = '/account/approve',
  COMMENTS = '/news/comments',
  COMMENT = '/comments',
  PRODUCTS = '/products',
  PROFILE = '/profile',
}
