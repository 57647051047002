import { Location, Params } from 'react-router-dom';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';

interface addToBreadcrumbsProps {
  newBreadcrumb: string;
  existingBreadcrumbs: BreadcrumbData<string>[];
  location: Location;
  params: Readonly<Params<string>>;
}
export const addToBreadcrumbs = ({
  newBreadcrumb,
  existingBreadcrumbs,
  location,
  params,
}: addToBreadcrumbsProps) => {
  existingBreadcrumbs.push({
    breadcrumb: newBreadcrumb,
    location: { ...location },
    key: location.pathname,
    match: {
      params,
      pathname: location.pathname,
      route: {
        ...location,
        breadcrumb: newBreadcrumb,
      },
      pattern: {
        end: true,
        path: location.pathname,
      },
    },
  });
};
