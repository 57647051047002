import { isEmpty, omitBy } from 'lodash';
import queryString, { ParseOptions } from 'query-string';
import { NavigateFunction } from 'react-router';

import { CustomTableFilterProps } from '../components/ui/Table/Table';

export const useQueryParams = <T>(
  search: string,
  defaultState?: T,
  options?: ParseOptions
): T => {
  // Parsing the searchBy value if we enter a number or space as the first character query-string would parse it as a number and as such it wouldn't work.
  // That's why we picked that property manually and parsed it.
  const searchBy = queryString.pick(search, ['searchBy']);
  const excludedSearchString = queryString.exclude(search, ['searchBy']);
  const searchByObject = queryString.parse(searchBy) as never as T;
  const object = queryString.parse(excludedSearchString, {
    parseNumbers: true,
    ...options,
  }) as never as T;
  return { ...defaultState, ...object, ...searchByObject };
};

export const updateURLWithQueryParams = <T>(
  navigate: NavigateFunction,
  path: string,
  query: T
): void => {
  const cleanedQuery = cleanQueryParams(query);
  navigate({
    pathname: path,
    search: cleanedQuery,
  });
};

export const prepareParams = <T>(params: CustomTableFilterProps<T>) => {
  return {
    ...params,
    page: params.page > 0 ? params.page - 1 : params.page,
  };
};

export const cleanQueryParams = <T>(params: T): string => {
  const cleanedParams = omitBy(params as never, (value) => {
    return value === '' || value === null;
  });

  return !isEmpty(cleanedParams)
    ? `?${queryString.stringify(cleanedParams as never)}`
    : '';
};
