import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { useAppSelector } from 'src/store/app/hooks';
import useMountTransition from 'src/utils/useMountTransition';

import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import classes from './LoadingProvider.module.scss';

interface LoadingProviderProps {
  transitionDuration?: number;
  loading?: boolean;
  children?: ReactNode;
}

const LoadingProvider = ({
  transitionDuration = 300,
  loading: forceLoading,
  children,
}: LoadingProviderProps) => {
  const loading = useAppSelector((state) => state.global.globalLoading);

  const shouldRender = useMountTransition(
    forceLoading || loading,
    transitionDuration
  );

  return (
    <>
      {children}
      {(shouldRender || loading) &&
        createPortal(
          <div
            style={{ transitionDuration: `${transitionDuration}ms` }}
            className={classNames({
              [classes.Backdrop]: true,
              [classes.BackdropAnimateIn]: loading && shouldRender,
            })}
          >
            <ProgressIndicator type="indeterminate" />
          </div>,
          document.body
        )}
    </>
  );
};

export default LoadingProvider;
