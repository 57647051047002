import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoadingProvider from 'src/components/ui/LoadingProvider/LoadingProvider';
import { routes } from 'src/routes';

const Login = React.lazy(() => import('../Login/Login'));
const PageNotFound = React.lazy(() => import('../PageNotFound/PageNotFound'));

const UnauthenticatedRouter = () => {
  return (
    <Suspense fallback={<LoadingProvider loading={true} />}>
      <Routes>
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes.PAGE_NOT_FOUND} element={<PageNotFound />} />
        <Route path={'*'} element={<Navigate to={routes.LOGIN} />} />
      </Routes>
    </Suspense>
  );
};

export default UnauthenticatedRouter;
