import { createSlice } from '@reduxjs/toolkit';

import {
  USERS_SLICE,
  usersForApproveLoaded,
  usersLoaded,
} from '../actions/usersActions';
import { UsersList } from '../models/Users';

export interface UsersState {
  usersList: UsersList;
  usersForApproveList: UsersList;
}

const initialState: UsersState = {
  usersList: null,
  usersForApproveList: null,
};

const usersSlice = createSlice({
  name: USERS_SLICE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(usersLoaded, (state, action) => {
      state.usersList = action.payload;
    });
    builder.addCase(usersForApproveLoaded, (state, action) => {
      state.usersForApproveList = action.payload;
    });
  },
});

export default usersSlice.reducer;
