import { createSlice } from '@reduxjs/toolkit';

import { NEWS_SLICE, newsLoaded } from '../actions/newsActions';
import { NewsList } from '../models/News';

export interface NewsState {
  newsList: NewsList;
}

const initialState: NewsState = {
  newsList: null,
};

const newsSlice = createSlice({
  name: NEWS_SLICE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(newsLoaded, (state, action) => {
      state.newsList = action.payload;
    });
  },
});

export default newsSlice.reducer;
