import { faNewspaper, faTag, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import {
  SidebarCategory,
  SidebarSubCategory,
} from 'nchain-design-system/lib/components/Sidebar/Sidebar.interface';
import React from 'react';

import { routes } from '../../../routes';

interface SidebarSubCategoriesExtension extends SidebarSubCategory {
  breadcrumb: string;
}

interface SidebarCategoriesExtension extends SidebarCategory {
  breadcrumb: string;
  subCategories?: SidebarSubCategoriesExtension[];
}

export const sidebarCategories: SidebarCategoriesExtension[] = [
  {
    icon: <FontAwesomeIcon icon={faUsers} />,
    label: i18next.t('common.users'),
    pathname: routes.USERS,
    breadcrumb: i18next.t('common.users'),
    subCategories: [
      {
        label: i18next.t('common.users_list'),
        pathname: `${routes.USERS}${routes.USERS_LIST}`,
        breadcrumb: i18next.t('common.users_list'),
      },
      {
        label: i18next.t('common.users_for_confirmation'),
        pathname: `${routes.USERS}${routes.USERS_FOR_CONFIRMATION}`,
        breadcrumb: i18next.t('common.users_for_confirmation'),
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faNewspaper} />,
    label: i18next.t('common.news'),
    pathname: routes.NEWS,
    breadcrumb: i18next.t('common.news'),
    subCategories: [
      {
        label: i18next.t('common.news_list'),
        pathname: `${routes.NEWS}${routes.NEWS_LIST}`,
        breadcrumb: i18next.t('common.news_list'),
      },
      {
        label: i18next.t('common.comments'),
        pathname: `${routes.NEWS}${routes.COMMENTS}`,
        breadcrumb: i18next.t('common.comments'),
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faTag} />,
    label: i18next.t('common.products'),
    pathname: routes.PRODUCTS,
    breadcrumb: i18next.t('common.products'),
    subCategories: [
      {
        label: i18next.t('common.products_list'),
        pathname: `${routes.PRODUCTS}${routes.PRODUCTS_LIST}`,
        breadcrumb: i18next.t('common.products_list'),
      },
    ],
  },
];
