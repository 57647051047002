import { Avatar, Dropdown, Navbar as NchainNavbar } from 'nchain-design-system';
import React, { useEffect, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { logout } from '../../../store/actions/authActions';
import { getProfile } from '../../../store/actions/profileActions';
import { useAppDispatch, useAppSelector } from '../../../store/app/hooks';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import styles from './Navbar.module.scss';

const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const profile = useAppSelector((state) => state.profile.profile);

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  return (
    <NchainNavbar
      startElement={<Breadcrumbs />}
      icons={[
        <Dropdown
          key={useId()}
          classes={{
            footer: styles.footer,
            dropdown: styles.dropdown,
          }}
          footerProps={{
            title: t('login.logout'),
            action: () => dispatch(logout()),
          }}
          placement={'bottom-end'}
        >
          <div className={styles.profileButton}>
            <Avatar size="M" label="Gedeon Richter" />
            <div>
              <span>Admin uporabnik</span>
              <span>{profile?.name}</span>
            </div>
          </div>
        </Dropdown>,
      ]}
    />
  );
};

export default Navbar;
