import { PaginatedApi } from './Api';

export enum Roles {
  USER = 'USER',
  ADMIN = 'ADMIN',
}

export enum Specialization {
  GYNECOLOGY = 'GYNECOLOGY',
  PSYCHIATRY = 'PSYCHIATRY',
  INTERNAL = 'INTERNAL',
  RHEUMATOLOGY = 'RHEUMATOLOGY',
  TRAUMATOLOGY = 'TRAUMATOLOGY',
  FAMILY = 'FAMILY',
  SPECIALIST = 'SPECIALIST',
  OTHER = 'OTHER',
}

export interface BasicUser {
  id: string;
  name: string;
  username: string;
  specialization: Specialization;
  createdAt: Date;
}

export interface User extends BasicUser {
  email: string;
  roles: Roles[];
  approved: boolean;
}

export interface UsersList extends PaginatedApi {
  users: User[];
}

export interface UserForm {
  email: string;
  username: string;
  name: string;
  specialization: Specialization;
}
