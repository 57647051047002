import { Breadcrumb, BreadcrumbItem } from 'nchain-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { addToBreadcrumbs } from '../../../utils/breadcrumbsUtils';
import { sidebarCategories } from '../Layout/SidebarCategories';
import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();

  const breadcrumbsRoutes = sidebarCategories.map((route) => {
    return {
      ...route,
      path: route.pathname,
      children: route?.subCategories?.map((sub) => {
        return {
          ...sub,
          path: sub.pathname,
        };
      }),
    };
  });

  const breadcrumbs = useBreadcrumbs(breadcrumbsRoutes, {
    disableDefaults: true,
  });

  if (location.pathname.includes('faq')) {
    addToBreadcrumbs({
      newBreadcrumb: `${t('faqs.title')} - ${location.pathname.replace(
        '/products/faq/',
        ''
      )}`,
      existingBreadcrumbs: breadcrumbs,
      params,
      location,
    });
  }

  if (location.pathname.includes('side-effects')) {
    addToBreadcrumbs({
      newBreadcrumb: `${t('side_effects.title')} - ${location.pathname.replace(
        '/products/side-effects/',
        ''
      )}`,
      existingBreadcrumbs: breadcrumbs,
      params,
      location,
    });
  }

  return (
    <Breadcrumb onClick={(href) => navigate(href)}>
      {/* eslint-disable-next-line */}
      {breadcrumbs.map(({ match, breadcrumb }: any) => (
        <BreadcrumbItem
          href={match.pathname}
          key={match.pathname}
          className={styles.breadcrumbItem}
        >
          {breadcrumb}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
