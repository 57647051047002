import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { SnackbarType } from 'nchain-design-system';
import { put } from 'redux-saga/effects';

import instance from '../../axios';
import { CustomTableFilterProps } from '../../components/ui/Table/Table';
import { apiRoutes } from '../../constants/apiConstants';
import { cleanQueryParams, prepareParams } from '../../utils/useQueryParams';
import {
  commentsLoad,
  commentsLoaded,
  deleteComment,
} from '../actions/commentsActions';
import {
  addError,
  addSnackbar,
  removeModal,
  setGlobalLoading,
} from '../features/globalSlice';
import { Comments } from '../models/Comments';

export function* CommentsLoadedSaga(
  action: ReturnType<typeof commentsLoad>
): Generator {
  const params = prepareParams(
    action.payload as CustomTableFilterProps<Comments>
  );
  const newParams = cleanQueryParams(params);

  yield put(setGlobalLoading(true));
  try {
    const response = (yield instance.get(
      `${apiRoutes.COMMENTS}${newParams}`
    )) as AxiosResponse<Comments>;

    yield put(commentsLoaded(response.data));
  } catch (e) {
    const error = e as Error;
    yield put(addError({ actionType: action.type, error: error.message }));
    yield put(
      addSnackbar({
        type: SnackbarType.FAILURE,
        title: i18next.t('common.snackbar_error_title'),
        body: i18next.t('common.snackbar_error_body'),
      })
    );
  } finally {
    yield put(setGlobalLoading(false));
  }
}

export function* DeleteCommentSaga(
  action: ReturnType<typeof deleteComment>
): Generator {
  yield put(setGlobalLoading(true));
  try {
    yield instance.delete(
      `${apiRoutes.NEWS}/${action.payload.newsId}${apiRoutes.COMMENT}/${action.payload.commentId}`
    );
    yield put(
      addSnackbar({
        type: SnackbarType.SUCCESS,
        title: i18next.t('comments_snackbar.success_title'),
        body: i18next.t('comments_snackbar.success_body'),
      })
    );

    action.payload.func();
  } catch (e) {
    const error = e as Error;
    yield put(
      addSnackbar({
        type: SnackbarType.FAILURE,
        title: i18next.t('comments_snackbar.error_title'),
        body: i18next.t('comments_snackbar.error_body'),
      })
    );
    yield put(addError({ actionType: action.type, error: error.message }));
  } finally {
    yield put(removeModal());
    yield put(setGlobalLoading(false));
  }
}
