import { createAction } from '@reduxjs/toolkit';

import { CustomTableFilterProps } from '../../components/ui/Table/Table';
import { ProductForm, ProductList, ProductsData } from '../models/Products';

export const PRODUCTS_SLICE = 'products';

export const productsLoad = createAction<CustomTableFilterProps<ProductList>>(
  `${PRODUCTS_SLICE}/load`
);
export const productsLoaded = createAction<ProductList>(
  `${PRODUCTS_SLICE}/loaded`
);
export const deleteProduct = createAction<{ id: string; func: () => void }>(
  `${PRODUCTS_SLICE}/delete`
);

export const addProduct = createAction<{
  product: ProductForm;
}>(`${PRODUCTS_SLICE}/add`);

export const getProductById = createAction<{
  id: string;
  onSuccess: (item: ProductsData) => void;
  detailsOnly?: boolean;
}>(`${PRODUCTS_SLICE}/getProductById`);

export const editProduct = createAction<{
  id: string;
  productData: ProductForm;
  deletedFilesIndexes: number[];
}>(`${PRODUCTS_SLICE}/edit`);

export const getProductSideEffects = createAction<string>(
  `${PRODUCTS_SLICE}/getProductSideEffects`
);
