import { createAction } from '@reduxjs/toolkit';

import { CustomTableFilterProps } from '../../components/ui/Table/Table';
import { UserForm, UsersList } from '../models/Users';

export const USERS_SLICE = 'users';

export const usersLoad = createAction<CustomTableFilterProps<UsersList>>(
  `${USERS_SLICE}/load`
);
export const usersLoaded = createAction<UsersList>(`${USERS_SLICE}/loaded`);
export const usersForApproveLoad = createAction<
  CustomTableFilterProps<UsersList>
>(`${USERS_SLICE}/loadForApprove`);
export const usersForApproveLoaded = createAction<UsersList>(
  `${USERS_SLICE}/loadedForApprove`
);
export const createNewUser = createAction<UserForm>(`${USERS_SLICE}/addUser`);
export const approveNewUser = createAction<string>(
  `${USERS_SLICE}/approveNewUser`
);
