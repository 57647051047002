import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';

import authReducer from '../features/authSlice';
import commentsReducer from '../features/commentsSlice';
import globalReducer from '../features/globalSlice';
import newsReducer from '../features/newsSlice';
import productsReducer from '../features/productsSlice';
import profileReducer from '../features/profileSlice';
import usersReducer from '../features/usersSlice';
import { watchAll } from '../sagas';

const sagaMiddleware = createSagaMiddleware();
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
  reducer: {
    auth: authReducer,
    global: globalReducer,
    news: newsReducer,
    users: usersReducer,
    comments: commentsReducer,
    products: productsReducer,
    profile: profileReducer,
    router: routerReducer,
  },
  middleware: (defaultMiddleware) => [
    ...defaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
    routerMiddleware,
  ],
});

sagaMiddleware.run(watchAll);

export const history = createReduxHistory(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
