import { createSlice } from '@reduxjs/toolkit';

import { COMMENTS_SLICE, commentsLoaded } from '../actions/commentsActions';
import { Comments } from '../models/Comments';

export interface CommentsState {
  commentsList: Comments;
}

const initialState: CommentsState = {
  commentsList: null,
};

const commentsSlice = createSlice({
  name: COMMENTS_SLICE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(commentsLoaded, (state, action) => {
      state.commentsList = action.payload;
    });
  },
});

export default commentsSlice.reducer;
