import axios from 'axios';

import { logout } from './store/actions/authActions';
import { store } from './store/app/store';

// Check out config if you need to add new cases - https://github.com/axios/axios

const instance = axios.create({
  baseURL: process.env.REACT_APP_BE_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth?.user?.token || '';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.Accept = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Error handling interceptor - replace with according action (right now logged in console)
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (400 === error.response.status) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

// Authentication failed interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      store.dispatch(logout());
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
