import React from 'react';
import { useAppSelector } from 'src/store/app/hooks';

import AuthenticatedRouter from './AuthenticatedRouter';
import UnauthenticatedRouter from './UnauthenticatedRouter';

const Router = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.user?.token);

  return isAuthenticated ? <AuthenticatedRouter /> : <UnauthenticatedRouter />;
};

export default Router;
