import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PROFILE_SLICE } from '../actions/profileActions';
import { Profile } from '../models/Profile';

interface ProfileSlice {
  profile: Profile;
}

const initialState: ProfileSlice = {
  profile: null,
};

const profileSlice = createSlice({
  name: PROFILE_SLICE,
  initialState,
  reducers: {
    getProfileSetSuccess(state, action: PayloadAction<Profile>) {
      state.profile = action.payload;
    },
  },
});

export const { getProfileSetSuccess } = profileSlice.actions;
export default profileSlice.reducer;
