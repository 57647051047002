import { createAction } from '@reduxjs/toolkit';

import { CustomTableFilterProps } from '../../components/ui/Table/Table';
import { Comments } from '../models/Comments';

export const COMMENTS_SLICE = 'comments';

export const commentsLoad = createAction<CustomTableFilterProps<Comments>>(
  `${COMMENTS_SLICE}/load`
);
export const commentsLoaded = createAction<Comments>(
  `${COMMENTS_SLICE}/loaded`
);
export const deleteComment = createAction<{
  newsId: string;
  commentId: number;
  func: () => void;
}>(`${COMMENTS_SLICE}/delete`);
