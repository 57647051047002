import { Modal } from 'nchain-design-system';
import React, { ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/app/hooks';
import { removeModal } from 'src/store/features/globalSlice';
import { ModalType } from 'src/store/models/Modal';

import styles from './ModalProvider.module.scss';

interface ModalProviderProps {
  children: ReactNode;
  className?: string;
  transitionDuration?: number;
}

const ModalProvider = ({ children }: ModalProviderProps) => {
  const dispatch = useAppDispatch();

  const modal = useAppSelector((state) => state.global.modal);
  const open = !!modal;

  const showModalType = () => {
    switch (modal?.type) {
      case ModalType.CONFIRM:
        return (
          <Modal
            title={modal.title}
            body={modal.body}
            onClose={() => dispatch(removeModal())}
            open={open}
            primaryButtonText={modal.primaryButtonText}
            primaryButtonAction={modal.primaryAction}
            secondaryButtonText={modal.secondaryButtonText}
            classes={{
              footer: styles.customButton,
            }}
            PortalProps={{
              closeOnBackdropClick: false,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {children}
      {showModalType()}
    </>
  );
};

export default ModalProvider;
