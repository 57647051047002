import React from 'react';

import Router from './components/pages/Router/Router';
import LoadingProvider from './components/ui/LoadingProvider/LoadingProvider';
import ModalProvider from './components/ui/ModalProvider/ModalProvider';
import SnackbarProvider from './components/ui/SnackbarProvider/SnackbarProvider';
import useGaTracker from './utils/googleAnalytics';

const App = () => {
  useGaTracker();

  return (
    <LoadingProvider>
      <ModalProvider>
        <SnackbarProvider>
          <Router />
        </SnackbarProvider>
      </ModalProvider>
    </LoadingProvider>
  );
};

export default App;
