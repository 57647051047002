import { all, takeLatest } from 'redux-saga/effects';

import { login } from '../actions/authActions';
import { commentsLoad, deleteComment } from '../actions/commentsActions';
import {
  createNewNews,
  deleteNews,
  getNewsById,
  newsLoad,
  patchNews,
} from '../actions/newsActions';
import {
  addProduct,
  deleteProduct,
  editProduct,
  getProductById,
  getProductSideEffects,
  productsLoad,
} from '../actions/productsActions';
import { getProfile } from '../actions/profileActions';
import {
  approveNewUser,
  createNewUser,
  usersForApproveLoad,
  usersLoad,
} from '../actions/usersActions';
import { AuthLoginSaga } from './authSaga';
import { CommentsLoadedSaga, DeleteCommentSaga } from './commentsSaga';
import {
  CreateNewNewsSaga,
  DeleteNewsSaga,
  GetNewsByIdSaga,
  NewsLoadedSaga,
  PatchNewsSaga,
} from './newsSaga';
import {
  AddProductSaga,
  DeleteProductSaga,
  EditProductSaga,
  GetProductByIdSaga,
  GetProductSideEffectsSaga,
  ProductsLoadedSaga,
} from './productsSaga';
import { GetProfileSaga } from './profileSaga';
import {
  ApproveNewUserSaga,
  CreateNewUserSaga,
  UsersForApproveLoadedSaga,
  UsersLoadedSaga,
} from './usersSaga';

export function* watchAll(): Generator {
  yield all([
    takeLatest(login.type, AuthLoginSaga),
    takeLatest(newsLoad.type, NewsLoadedSaga),
    takeLatest(createNewNews.type, CreateNewNewsSaga),
    takeLatest(deleteNews.type, DeleteNewsSaga),
    takeLatest(getNewsById.type, GetNewsByIdSaga),
    takeLatest(patchNews.type, PatchNewsSaga),
    takeLatest(usersLoad.type, UsersLoadedSaga),
    takeLatest(usersForApproveLoad.type, UsersForApproveLoadedSaga),
    takeLatest(createNewUser.type, CreateNewUserSaga),
    takeLatest(approveNewUser.type, ApproveNewUserSaga),
    takeLatest(commentsLoad.type, CommentsLoadedSaga),
    takeLatest(deleteComment.type, DeleteCommentSaga),
    takeLatest(productsLoad.type, ProductsLoadedSaga),
    takeLatest(deleteProduct.type, DeleteProductSaga),
    takeLatest(addProduct.type, AddProductSaga),
    takeLatest(getProductById.type, GetProductByIdSaga),
    takeLatest(editProduct.type, EditProductSaga),
    takeLatest(getProductSideEffects.type, GetProductSideEffectsSaga),
    takeLatest(getProfile.type, GetProfileSaga),
  ]);
}
